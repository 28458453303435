<template>
  <CRow>
    <CCol sm="12">

      <CCard>

        <CCardHeader v-if="kunde">
          <CIcon name="cil-calendar"/>
          Ein neues Projekt für {{kunde.name1}}
        </CCardHeader>

        <CCardBody>
          <ValidationObserver v-slot="{ invalid }">
            <CForm class="no-mb" @submit.prevent="newProjekt()">

              <ProjektText v-model="name" formLabel="Name"/>
              <ProjektText v-model="zusatz" formLabel="Zusatz" rules=""/>
              <ProjektText v-model="strasse" formLabel="Strasse" rules=""/>
              <ProjektText v-model="hausnr" formLabel="Hausnummer" rules=""/>
              <ProjektText v-model="plz" formLabel="Postleitzahl" rules=""/>
              <ProjektText v-model="ort" formLabel="Ort" rules=""/>
              <ProjektText v-model="ansprechpartner" formLabel="Ansprechpartner" rules=""/>
              <ProjektText v-model="telefon" formLabel="Telefon" rules=""/>
              <ProjektTextArea v-model="bemerkung" formLabel="Bemerkung" rules=""/>

              <FormError v-if="formError" :errormessage="formError"/>

              <CRow class="mt-4">
                <CCol col="12" class="text-right">
                  <CButton color="primary" class="px-4" :disabled="invalid" v-if="kunde" type="submit">Projekt ändern</CButton>
                </CCol>
              </CRow>

            </CForm>
          </ValidationObserver>
        </CCardBody>

      </CCard>

    </CCol>
  </CRow>
</template>

<script>
import Vue from 'vue'
import FormError from '@/components/FormError'
import ProjektText from '@/components/FormElements/Text'
import ProjektTextArea from '@/components/FormElements/TextArea'

export default {
  name: 'KundeNew',
  components: {
    FormError,
    ProjektText,
    ProjektTextArea
  },
  props: {
    uuid: [Number, String]
  },
  data () {
    return {
      kunde: null,
      name: '',
      zusatz: '',
      strasse: '',
      hausnr: '',
      plz: '',
      ort: '',
      ansprechpartner: '',
      telefon: '',
      bemerkung: '',
      formError: null
    }
  },
  mounted () {
    this.loadProjekt()
  },
  methods: {
    loadProjekt () {
      Vue.axios.get('/projekte/details/' + this.uuid)
        .then((response) => {
          this.name = response.data.name
          this.zusatz = response.data.zusatz
          this.strasse = response.data.strasse
          this.hausnr = response.data.hausnr
          this.plz = response.data.plz
          this.ort = response.data.ort
          this.ansprechpartner = response.data.ansprechpartner
          this.telefon = response.data.telefon
          this.bemerkung = response.data.bemerkung
          this.loadKunde(response.data.kunde.kundenid)
        })
    },
    loadKunde (kundenid) {
      Vue.axios.get('/kunden/get/' + kundenid)
        .then((response) => {
          this.kunde = response.data
        })
    },
    newProjekt () {
      Vue.axios.put('/projekte/put/' + this.uuid, {
        kunde: this.kunde.kundenid,
        name: this.name,
        zusatz: this.zusatz,
        strasse: this.strasse,
        hausnr: this.hausnr,
        plz: this.plz,
        ort: this.ort,
        ansprechpartner: this.ansprechpartner,
        telefon: this.telefon,
        bemerkung: this.bemerkung
      }).then((response) => {
        this.$router.push({ path: '/projekte/uebersicht/' + this.kunde.kundenid })
        this.$snotify.success('Das Projekt wurde geändert.', {
          position: 'rightTop',
          timeout: 4000
        })
        this.setDefaults()
      }).catch((error) => {
        this.formError = error.response
      })
    }
  }
}
</script>
